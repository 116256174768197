import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

class CollectionsSection {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.boxEl = this.root.querySelectorAll('.collection-box');

        return true;
    }

    init() {
        this.setBoxes();
    }

    setBoxes() {
        const { innerWidth } = window;
        if (!this.boxEl || innerWidth < 992) return;

        this.boxEl.forEach((box, i) => {
            let yTransformStart = -100;
            let yTransformEnd = 0;
            let marginTopStart = null;
            let marginTopEnd = null;

            console.log(box, i);

            if (i === 1) {
                yTransformStart = null;
                yTransformEnd = null;
                marginTopStart = 260;
                marginTopEnd = 160;
            }

            gsap.fromTo(
                box,
                {
                    y: yTransformStart,
                    marginTop: marginTopStart,
                },
                {
                    y: yTransformEnd,
                    marginTop: marginTopEnd,
                    ease: 'power0.inOut',
                    scrollTrigger: {
                        trigger: this.root,
                        start: 'top-=30% top',
                        end: 'bottom-=100% top',
                        scrub: 0.2,
                    },
                }
            );
        });
    }
}

const InitCollections = () => {
    const sections = document.querySelectorAll('[data-collections]');
    if (!sections) return;

    sections.forEach(item => {
        new CollectionsSection(item);
    });
};

// InitCollections();
